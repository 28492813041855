.badge-container {
  display: flex;
  position: relative;
  cursor: pointer;
}

.user-img-container {
  display: flex;
  align-items: center;
}

.user-profile-arrow {
  margin: 0 10px;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: contain;
}

.user-name-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
}

.user-name-label {
  color: var(--color-light-font);
  font-weight: bold;
}

.user-badge-label {
  margin: 0;
  padding: 0;
  padding-bottom: 8px;
  font-size: 0.9rem;
  user-select: none;
}

.nav-user-badge-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 10;
  padding: 10px;
  width: max-content;
  min-width: 185px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}

.nav-user-badge-popup::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: white;
}

.nav-user-badge-popup p {
  padding: 0;
  margin: 0;
  color: black;
}

.nav-user-badge-popup-buttons-container button:not(:last-child) {
  margin-left: 10px;
}

.fadein {
  animation: fadein 500ms;
  -webkit-animation: fadein 500ms;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
