/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

:root {
  --color-primary: #1f3559;
  --color-primary-dark: #1e2e4a;
  --color-primary-light: #23456e;
  --color-primary-lighter: #2a5c8c;
  --color-highlighted-blue: #00ccbe;
  --color-gray-border: #dddddd;
  --color-light-gray-bg: #fafafa;
  --color-gray-bg: #f5f5f5;
  --color-light-font: #f8ebeb;
  --color-bright-font: #ffffff;
  --color-dimed-font: #e6dada;
  --color-gray-font: #a69999;
  --color-dark-font: #333333;
  --color-default-badge-bg: #59737c;
  --color-default-badge-hover-bg: #6d8d98;
  --color-default-badge-icon: #f8ebeb;
  --color-orange: #ff7043;
  --color-green: #4caf50;
  --color-light-blue: #61c3fa;
}

@font-face {
  font-family: Ar;
  src: url("./assets/fonts/AbdoMaster-Light.woff");
}

html,
body {
  min-height: 100% !important;
  height: 100%;
  font-size: 14px;
}

@media (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: Ar, sans-serif !important;
}

.rtl {
  direction: rtl;
}

.rtl-text {
  text-align: right;
}

.no-outline {
  outline: 0 none !important;
}

/* Scroll-bar style */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(0, var(--color-highlighted-blue), var(--color-primary-lighter));
}
