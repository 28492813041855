/* LAYOUT */
.g-table-container {
  margin: 10px;
  min-height: 220px;
}
table.g-table tr {
  border: 1px solid rgba(5, 3, 66, 0.1);
  box-shadow: -1px 0px 5px -4px rgba(5, 3, 66, 0.5);
}

table.g-table {
  width: 100%;
  text-align: right;
  border-spacing: 0 6px;
  border-collapse: collapse;
  position: relative;
  direction: rtl;
}

table.g-table tbody {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.075) !important;
}

table.g-table thead th {
  font-size: 12px;
  font-weight: bold;
  color: var(--font-color-dark-gray);
  text-align: right;
  user-select: none;
}

table.g-table thead tr {
  background-color: transparent;
  box-shadow: none;
}

table.g-table th {
  padding: 14px 12px;
  white-space: nowrap;
}

table.g-table thead th input {
  font-size: 12px;
  padding: 16px;
}

table.g-sort-icon {
  flex-grow: 1;
}

/* Table Body */

table.g-table tbody:empty::after {
  content: "🔍";
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  box-shadow: -1px 0px 5px -4px rgba(5, 3, 66, 0.5);
  border: 1px solid rgba(5, 3, 66, 0.1);
}

table.g-table tbody td {
  font-size: 14px;
}

table.g-table tr {
  background-color: #ffffff;
  line-height: 35px;
}

table.g-table td {
  padding: 2px 12px;
  white-space: nowrap;
}

table.g-table .table-actions {
  display: flex;
  align-items: center;
}

table.g-table .table-action-icon:hover {
  cursor: pointer;
  color: var(--gce-primary-color);
}

table.g-table .table-actions .table-action-icon:not(:last-child) {
  margin-right: 10px;
}

/* PAGINATION */

.g-table-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 7;
  box-shadow: -1px 0px 5px -4px rgba(5, 3, 66, 0.5);
  border-right: 1px solid rgba(5, 3, 66, 0.1);
  border-left: 1px solid rgba(5, 3, 66, 0.1);
  border-bottom: 1px solid rgba(5, 3, 66, 0.1);
  padding: 5px 0px 5px 0px;
}

.g-table-pagination p {
  margin: 0 10px;
}
.g-table-pagination label {
  padding: 10px 5px 4px 0px;
}

.g-table-select {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 7;
  font-weight: bold;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 2px 5px 2px 5px;
  margin-right: 15px;
}
.g-table-btnpage {
  width: 30px;
  height: 30px;
  margin: 5px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid lightgray;
}
