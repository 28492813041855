.setting-container {
  height: 100%;
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.setting-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
}

.setting-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 28%;
  height: 200px;
  margin: 1%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: 400ms;
  font-size: 32px;
  color: var(--color-primary-light);
  margin-bottom: 15px;
}

.setting-card:hover {
  box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.6);
  font-size: 36px;
}

.setting-card-title {
  text-align: center;
  margin: 0;
  font-weight: bold;
  margin-top: 20px;
  font-size: 16px;
  color: var(--color-primary-light);
  white-space: nowrap;
}
