.map-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.map-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.map-side-container {
    position: relative;
    z-index: 1;
    width: 330px;
    background-color: var(--color-light-gray-bg);
    border-right: 1px solid var(--color-gray-border);
    box-shadow: 0.5rem 0.3rem 0.5rem rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    overflow-x: hidden;
}

.map-side-animated-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    overflow-y: auto;
}

.select-option-prompt-text {
    text-align: center;
    margin: 10px;
}
