.login-page-container {
  direction: rtl;
  text-align: right;
  height: 100vh;
  width: 100vw;
}

.login-page-logo-container {
  margin-bottom: 10px;
  text-align: center;
}

.login-page-logo {
  height: 80px;
  object-fit: contain;
}

.login-page-background {
  background-image: url(../../../assets/images/irbid_city.jpg);
  background-size: cover;
  filter: blur(15px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.login-page-wrapper {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #23456eab;
}

.login-page-form {
  width: 400px;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 12px;
  border: 1px solid lightgray;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  background-color: white;
}

.login-page-footer-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.login-page-footer-wrapper label {
  margin: 0 15px;
  color: crimson;
  font-weight: bold;
}

.login-page-footer-wrapper .login-page-login-btn {
  width: 130px;
}

.login-page-footer-wrapper .login-page-login-btn p {
  margin-bottom: 5px;
}

.login-page-footer-wrapper .login-page-login-btn .spinner-border-sm {
  margin-bottom: 4px;
}
