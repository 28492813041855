.map-bavbar {
    z-index: 2;
    padding: 5px 10px;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.075);
    border-bottom: 1px solid var(--color-gray-border);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.map-bavbar .btn {
    margin: 5px;
    flex-grow: 1;
}

.map-bavbar .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}
