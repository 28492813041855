.analyze-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.analyze-map-container {
  flex: 1;
  display: flex;
}

.analyze-content-container {
  position: relative;
  z-index: 1;
  width: 330px;
  background-color: var(--color-light-gray-bg);
  border-right: 1px solid var(--color-gray-border);
  box-shadow: 0.5rem 0.3rem 0.5rem rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  overflow-x: hidden;
}

.analyze-pie-container {
  /* position: absolute;
  right: 10px;
  top: 20px; */
  padding-bottom: 8px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: rgba(35, 69, 110, 0.9);
}
