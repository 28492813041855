.compass-container {
  position: relative;
  height: 200px;
  width: 200px;
}

.compass-img {
  position: absolute;
  height: 180px;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.compass-angle {
  position: absolute;
  left: calc(50% - 23px);
  right: calc(50% - 23px);
  bottom: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 26px;
  font-weight: bold;
  color: rgb(80, 80, 80);
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}

.compass-needle-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transition: all 200ms;
}
/* 
.compass-needle {
  height: 0;
  width: 0;
  border: 10px solid rgb(167, 4, 26);
  border-bottom: unset;
  border-right-color: transparent;
  border-left-color: transparent;
} */

.compass-needle-img {
  height: 130px;
  position: absolute;
  top: calc(50% - 67.5px);
  left: calc(50% - 8.22px);
  transform-origin: 50% calc(50% + 2.5px);
  transition: all 200ms;
}
