.map {
  width: 100%;
  flex: 1;
}
.map-legend {
  width: 180px;
  position: absolute;
  right: 8px;
  bottom: 0px;
  z-index: 2;
  background-color: #869cc2;
  padding: 5px;
  bottom: 38px;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  opacity: 0.8;
  border-radius: 4px;
}

.ol-control button {
  height: 2em;
  width: 2em;
}

.ol-control.layer-switcher {
  top: 3px;
  right: 50px;
  background-color: transparent;
  height: inherit;
  padding: 0;
}

.ol-control.layer-switcher button {
  background-color: transparent;
}

.layer-switcher div.panel {
  direction: rtl;
  border-radius: 10px;
}

.layer-switcher div.panel > ul {
  padding-right: 0;
  text-align: right;
}

.layer-switcher div.panel > ul ul {
  padding-right: 20px;
}

.custom-mouse-position {
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 2px 10px;
  padding-top: 0;
  margin: 1px;
  background: rgba(0, 60, 136, 0.5);
  border: 1px solid #eee;
  border-radius: 4px;
  border-top: none;
  color: #eee;
  font-size: 12px;
  text-align: center;
  will-change: contents, width;
  transition: all 0.25s;
}

.ol-globe {
  bottom: 2.5em !important;
}

.ol-control.ol-globe .ol-pointer {
  left: 50% !important;
}

.ol-control.ol-globe .ol-pointer::after {
  left: -10px !important;
}

.ol-control.ol-globe .ol-pointer::before {
  left: -5px;
}

.ol-search-control {
  direction: ltr;
}

.ol-search-control.ol-control.ol-searchgps {
  left: 4em;
}

.ol-search-control.ol-control.ol-searchgps > button:first-child:before {
  content: "X.Y";
  display: block;
  font-size: 10px;
  transform: unset;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #302790;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #302790;
}

.ol-button.ol-active button {
  background: rgba(60, 136, 0, 0.7);
}

.ol-toggle.ol-area-ctrl {
  right: 0.5em;
  top: 3em;
}

.ol-toggle.ol-length-ctrl {
  right: 0.5em;
  top: 5.5em;
}

.ol-clear-ctrl {
  right: 0.5em;
  top: 8em;
}

.ol-zoomslider {
  top: 6em !important;
}
