.dropDownShift_Container {
  border: 1px solid #2a5c8c96;
  border-top: 1px solid var(--color-gray-border);
  box-shadow: rgba(42, 92, 140, 0.18) 0.1rem 0.1rem 0.7rem 0.05rem;

}
.dropDownShift_formGroup {
  position: relative;
  margin-bottom: 0px;
  margin-top: 5px;
}
.dropDownShift_clearIcon {
  position: absolute !important ;
  left: 10px !important ;
  bottom: 10px !important ;
  color: gray;
  cursor: pointer;
}
.dropDownShift_searchInput {
  font-size: 12px;
  font-weight: 600;
}
.dropDownShift_Loading {
  position: absolute !important ;
  left: 10px !important ;
  bottom: 10px !important ;
  color: gray;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.dropdown-item{
  font-size: 12px!important;
  font-weight: bold!important;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
