@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    page-break-after: always;
    margin: 0px;
  }
  .landuse-report {
    width: 19.8cm;
    margin: 6mm;
    page-break-after: always;
  }
  .landuse-report-A3 {
    width: 27.1cm;
    margin: 6mm;
    page-break-after: always;
    .landuse-report-irbid {
      background-repeat: repeat;
      background-image: url("../../../../assets/images/watermark.png");
    }
  }
  table {
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}

.landuse-report-A4 {
  position: relative;
  width: 19.8cm;
  margin: 6mm;
  .landuse-report-irbid {
    background-repeat: repeat;
    background-image: url("../../../../assets/images/watermark.png");
  }
  .irbid-report-header {
    display: flex;
    height: 90px;
    margin-bottom: 10px;
    margin-top: 1.2cm;
  }
  .container-right {
    display: inline-block;
    text-align: center;
    width: 30%;
    height: 100%;
  }
  .container-right > label {
    margin: 0px !important;
  }
  .container-right-lbl {
    font-size: 12px;
    font-weight: bold;
    display: block;
    text-align: right;
    margin-top: 25px;
  }
  .container-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    height: 100%;
  }
  .logo-img {
    height: 80%;
    object-fit: contain;
  }
  .container-left {
    display: block;
    width: 35%;
    text-align: right;
    margin-left: 5px;
  }
  .container-left-div {
    display: flex;
    margin-bottom: 2px;
  }
  .container-left-input {
    flex: 1.25;
    font-size: 11px;
  }

  .container-left-bold-lbl {
    flex: 0.75;
    font-size: 15px;
    font-weight: bold;
    display: block;
  }
  .container-left-lbl {
    font-size: 12px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
    display: block;
    flex: 1;
  }
  .irbid-report-details {
    margin-top: 2px;
    /* line-height: 0px!important; */
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .irbid-report-details-h {
    /* margin-top: 10px; */
    display: grid;
    grid-template-columns: 50% 25% 25%;
  }
  .irbid-report-details-lbl {
    font-size: 11px;
    font-weight: bold;
    /* margin: 0px 5px; */
  }
  .irbid-report-details-input {
    font-size: 11px;
    width: 80%;
  }
  .irbid-report-details-apart {
    font-size: 11px;
    width: 40px;
  }
  .irbid-report-details-input-area {
    font-size: 11px;
    width: 72%;
  }
  .irbid-report-details-input-rule {
    font-size: 11px;
    width: 669px;
  }
  .irbid-report-footer-container {
    margin: 5px 0px;
    padding-right: 3px;
    border: 1px solid black;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    display: inline-block;
    width: 741px;
  }
  .irbid-report-footer-container > label {
    margin-bottom: 0px !important;
  }
  .irbid-report-footer {
    /* margin-top: 10px; */
    display: grid;
    grid-template-columns: 40% 40% 20%;
  }
  .irbid-report-footer-row {
    display: flex;
    margin: 0px 0px 0px 20px;
  }
  .irbid-report-footer-input {
    margin: 2px;
    width: 200px;
    font-size: 10px;
  }
  .irbid-report-footer-label {
    flex: 1;
    min-width: 67px;
  }
  .irbid-report-rules {
    border: 1px solid black;
    /* margin: 0px 5px; */
    width: 741px;
    text-align: center;
  }
  .irbid-report-rules > tr {
    /* line-height: 5px; */
    border: 1px solid black;
  }
  .irbid-report-rules-label {
    font-size: 9px;
    font-weight: bold;
  }

  .irbid-report-rules > tr > th {
    border: 1px solid black;
  }

  /*Map style*/

  .irbid_landuse_Report_map_contain {
    width: 19.6cm !important;
    border: 1px solid black;
    display: inline-block;
    height: 9.9cm !important;
    box-sizing: border-box;
    margin: 5px 0px 0px 0px;
    padding: 0px;
  }
  .irbid_landuse_Report_map {
    width: 19.5cm !important;
    height: 8.8cm !important;
    display: flex;
  }
  .irbid_landuse_Report_map-withApart {
    width: 9.75cm !important;
    height: 8.8cm !important;
    display: flex;
    float: left;
    border-right: 1px solid black;
  }
  .irbid_landuse_Report_scalecontainer {
    height: 1cm !important;
    margin: 0px !important;
  }
  .irbid_landuse_Report_container {
    height: 1cm !important;
    margin: 0px !important;
    display: flex;
    flex-direction: row;
    > label {
      font-size: 0.9rem;
      padding-top: 0.2rem;
    }
  }
  .irbid-map-legend {
    width: 125px;
    position: absolute;
    background-color: #003c88;
    padding: 5px;
    max-height: 300px;
    border-radius: 4px;
    max-width: 200px;
    overflow: auto;
    z-index: 10000;
    margin: 0px 5px 5px 10px;
    left: 0px;
    bottom: 0px;
  }
  .irbid_landuse_Report_rotate {
    width: 32px;
    height: 32px;
    left: 0px;
    position: absolute;
    background-color: #003c88;
    z-index: 10000;
    margin: 77px 0px 0px 2px;
    color: white;
  }
  .irbid_landuse_Report_label {
    left: 0px;
    width: 35px;
    text-align: center;
    position: absolute;
    font-size: 0.6rem;
    border: 1.2px outset #003c88;
    background-color: #003c88;
    z-index: 10000;
    margin: 45px 0px 0px 1px;
    color: white;
  }
  .landuse_Report_map_maincontain {
    min-height: 10.2cm !important;
    border: 1px solid black;
    margin: 5px 0px 5px 5px;
    display: block;
  }
  .landuse_Report_map_contain {
    width: 9.46cm !important;
    border: 1px solid black;
    margin: 5px;
    display: inline-block;
    height: 9.9cm !important;
  }
  .landuse_Report_map {
    width: 9.3cm !important;
    height: 8.8cm !important;
    display: flex;
  }

  .landuse_Report_scalecontainer {
    height: 1cm !important;
    margin: 0px !important;
  }

  .landuse_Report_grid_style {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-size: 1cm 1cm;
    background-image: linear-gradient(to right, grey 1px, transparent 1px),
      linear-gradient(to bottom, grey 1px, transparent 1px);
    pointer-events: none;
  }

  .irbid-report-notes {
    width: 19.6cm;
    margin: 5px 0px 0px 5px;
    border: 1px solid black;
    min-height: 70px;
    padding-bottom: 5px;
  }
  .irbid-report-notes-input {
    width: 19.6cm;
    margin: 5px 0px 0px 5px;
    border: 1px solid black;
    padding-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
    color: black;
  }

  .irbid_landuse_Report_map-withApart {
    position: relative;
    .ol-rotate {
      left: 0px !important;
      right: 329px !important;
    }
  }
  .irbid_landuse_Report_map {
    position: relative;
    .ol-rotate {
      left: 0px !important;
      right: 700px !important;
    }
  }
  .ol-rotate-reset {
    background-color: #003c88 !important;
  }
}
.landuse-report-A3 {
  position: relative;
  width: 29.1cm;
  margin: 6mm;
  .irbid-report-header {
    display: flex;
    height: 90px;
    margin-bottom: 10px;
    margin-top: 1.2cm;
  }
  .container-right {
    display: inline-block;
    text-align: center;
    width: 30%;
    height: 100%;
  }
  .container-right > label {
    margin: 0px !important;
  }
  .container-right-lbl {
    font-size: 12px;
    font-weight: bold;
    display: block;
    text-align: right;
    margin-top: 25px;
  }
  .container-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 100%;
  }
  .logo-img {
    height: 80%;
    object-fit: contain;
  }
  .container-left {
    display: block;
    width: 30%;
    text-align: right;
    margin-left: 5px;
  }
  .container-left-div {
    display: flex;
    margin-bottom: 2px;
  }
  .container-left-input {
    flex: 1.25;
    font-size: 11px;
  }

  .container-left-bold-lbl {
    flex: 0.75;
    font-size: 15px;
    font-weight: bold;
    display: block;
  }
  .container-left-lbl {
    font-size: 12px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
    display: block;
    flex: 1;
  }
  .irbid-report-details {
    margin-top: 2px;
    /* line-height: 0px!important; */
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .irbid-report-details-h {
    /* margin-top: 10px; */
    display: grid;
    grid-template-columns: 50% 25% 25%;
  }
  .irbid-report-details-lbl {
    font-size: 11px;
    font-weight: bold;
    /* margin: 0px 5px; */
  }
  .irbid-report-details-input {
    font-size: 11px;
    width: 80%;
  }
  .irbid-report-details-apart {
    font-size: 11px;
    width: 40px;
  }
  .irbid-report-details-input-area {
    font-size: 11px;
    width: 81%;
  }
  .irbid-report-details-input-rule {
    margin-bottom: 5px;
    font-size: 11px;
    width: 29.1cm;
  }
  .irbid-report-footer-container {
    margin: 5px 0px;
    padding-right: 3px;
    border: 1px solid black;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    display: inline-block;
    width: 29.1cm;
  }
  .irbid-report-footer-container > label {
    margin-bottom: 0px !important;
  }
  .irbid-report-footer {
    /* margin-top: 10px; */
    display: grid;
    grid-template-columns: 40% 40% 20%;
  }
  .irbid-report-footer-row {
    display: flex;
    margin: 0px 0px 0px 20px;
  }
  .irbid-report-footer-input {
    margin: 2px;
    width: 200px;
    font-size: 10px;
  }
  .irbid-report-footer-label {
    flex: 1;
    min-width: 67px;
  }
  .irbid-report-rules {
    border: 1px solid black;
    width: 29.1cm;
    text-align: center;
  }
  .irbid-report-rules > tr {
    /* line-height: 5px; */
    border: 1px solid black;
  }
  .irbid-report-rules-label {
    font-size: 9px;
    font-weight: bold;
  }

  .irbid-report-rules > tr > th {
    border: 1px solid black;
  }

  /*Map style*/

  .irbid_landuse_Report_map_contain {
    width: 29.1cm !important;
    border: 1px solid black;
    display: inline-block;
    height: 9.9cm !important;
    box-sizing: border-box;
    margin: 5px 0px 0px 0px;
    padding: 0px;
  }
  .irbid_landuse_Report_map {
    width: 29.1cm !important;
    height: 8.8cm !important;
    display: flex;
  }
  .irbid_landuse_Report_map-withApart {
    width: 13.85cm !important;
    height: 8.8cm !important;
    display: flex;
    float: left;
    border-right: 1px solid black;
  }
  .irbid_landuse_Report_scalecontainer {
    height: 1cm !important;
    margin: 0px !important;
  }
  .irbid-map-legend {
    width: 125px;
    position: absolute;
    background-color: #003c88;
    padding: 5px;
    max-height: 300px;
    border-radius: 4px;
    max-width: 200px;
    overflow: auto;
    z-index: 10000;
    margin: 0px 5px 5px 2px;
    left: 0px;
    bottom: 0px;
  }
  .irbid_landuse_Report_rotate {
    width: 32px;
    height: 32px;
    left: 0px;
    position: absolute;
    background-color: #003c88;
    z-index: 10000;
    margin: 10px 0px 0px 2px;
    color: white;
  }

  .landuse_Report_map_maincontain {
    min-height: 10.2cm !important;
    border: 1px solid black;
    margin: 5px 0px 5px 5px;
    display: block;
  }
  .landuse_Report_map_contain {
    width: 12cm !important;
    border: 1px solid black;
    margin: 5px;
    display: inline-block;
    height: 9.9cm !important;
  }
  .landuse_Report_map {
    width: 12cm !important;
    height: 8.8cm !important;
    display: flex;
  }

  .landuse_Report_scalecontainer {
    height: 1cm !important;
    margin: 0px !important;
  }

  .landuse_Report_grid_style {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-size: 1cm 1cm;
    background-image: linear-gradient(to right, grey 1px, transparent 1px),
      linear-gradient(to bottom, grey 1px, transparent 1px);
    pointer-events: none;
  }
  .irbid-report-notes {
    width: 29.1cm;
    margin: 5px 0px 0px 5px;
    border: 1px solid black;
    min-height: 70px;
    padding-bottom: 5px;
  }
  .irbid-report-notes-input {
    width: 29.1cm;
    margin: 5px 0px 0px 5px;
    border: 1px solid black;
    padding-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
    color: black;
  }
  .irbid_landuse_Report_map-withApart {
    .ol-rotate {
      left: 0px !important;
      right: 19.4cm !important;
    }
  }
  .irbid_landuse_Report_map {
    .ol-rotate {
      left: 0px !important;
      right: 29.1cm !important;
    }
  }
  .ol-rotate-reset {
    background-color: #003c88 !important;
  }
}
