a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.navbar-nav {
    align-items: baseline;
    padding-bottom: 5px;
    padding-right: 0;
}

.custom-navbar {
    padding-top: 4px;
    padding-bottom: 4px;
    background: linear-gradient(180deg, var(--color-primary-dark), var(--color-primary-light));
    color: var(--color-light-font);
    border-bottom: 1px solid var(--color-primary-dark);
    margin: 0;
    height: 67px;
}

.custom-navbar-toggler {
    color: var(--color-light-font);
}

.active-link {
    color: #000;
    font-weight: bold;
    font-size: 1.1rem;
}

.logo-image {
    width: 50px;
    margin-left: 10px;
}

.burger-menu-icon {
    width: 1.2rem !important;
    height: 1.2rem !important;
    cursor: pointer;
    margin-left: 10px;
    color: var(--color-light-font);
}

.site-header {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    user-select: none;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.15);
}

.spacer {
    flex-grow: 1;
}

.navbar-divider {
    width: 1px;
    height: 35px;
    margin: 0 15px;
    background-color: var(--color-default-badge-bg);
}
