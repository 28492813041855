.site-body {
    position: relative;
    overflow: hidden;
    height: 100vh
}

.site-content {
    position: absolute;
    top: 66px;
    bottom: 0;
    right: 65px;
    left: 0;
    z-index: 1;
    height: calc(100vh - 66px);
    overflow: auto;
    background-color: var(--color-light-gray-bg);
    transition: right 200ms;
}

.site-content.side-menu-open {
    right: 255px;
}

.side-nav-menu {
    position: absolute;
    top: 66px;
    bottom: 0;
    right: -190px;
    z-index: 2;
    height: calc(100vh - 66px);
    width: 255px;
    overflow: auto;
    /*background-color: var(--color-primary-dark);*/
    background: linear-gradient(0, var(--color-primary-dark), var(--color-primary-light));
    box-shadow: -0.1rem 0 0.75rem rgba(0, 0, 0, 0.4);
    transition: right 200ms;
}

/*.side-nav-menu:hover,*/
.side-nav-menu.side-menu-open {
    right: 0;
}

.side-menu-ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.side-menu-ul a {
    text-decoration: none !important;
}

.side-menu-ul.closed {
    margin-right: 190px;
}

.side-nav-li {
    display: flex;
    align-items: center;
    color: var(--color-light-font);
    margin: 5px 15px;
    padding: 8px 10px 10px 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 300ms;
}

.side-nav-li.closed {
    margin: 0 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.side-nav-li:hover {
    background-color: var(--color-primary-lighter);
}

.side-menu-label {
    margin: 0 20px 0 0;
    padding: 0;
}

.side-nav-li-icon {
    width: 1.5rem !important;
}

.side-nav-li.closed .side-nav-li-icon {
    font-size: 1.8rem;
}

.active-side-link .side-nav-li {
    color: var(--color-bright-font);
    background-color: var(--color-highlighted-blue);
    font-weight: bold;
}

.active-side-link-icon .side-nav-li.closed {
    color: var(--color-bright-font);
    background-color: var(--color-highlighted-blue);
}

.vertical-flex-separation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.copyright-label {
    padding: 20px 20px 0 20px;
    text-align: center;
    direction: ltr;
    font-size: 0.75rem;
    color: var(--color-light-font);
}
