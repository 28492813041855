@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    page-break-after: always;
    margin: 0px;
  }
  .landuse-report {
    width: 19.8cm;
    margin: 6mm;
    page-break-after: always;
  }
   table { page-break-after:auto }
   tr    { page-break-inside:avoid; page-break-after:auto }
   td    { page-break-inside:avoid; page-break-after:auto }
   thead { display:table-header-group }
   tfoot { display:table-footer-group }
}

.landuse-report {
  position: relative;
  width: 19.8cm;
  margin: 6mm;
}

.landuse_Report_map_maincontain {
  min-height: 10.2cm !important;
  border: 1px solid black;
  margin: 5px 0px 5px 5px;
  display: block;
}
.landuse_Report_map_contain {
  width: 9.46cm !important;
  border: 1px solid black;
  margin: 5px;
  display: inline-block;
  height: 9.9cm !important;
}
.landuse_Report_map {
  width: 9.3cm !important;
  height: 8.8cm !important;
  display: flex;
}

.landuse_Report_scalecontainer {
  height: 1cm !important;
  margin: 0px !important;
}

.landuse_Report_grid_style {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-size: 1cm 1cm;
  background-image: linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px);
  pointer-events: none;
}
