.nav-notification-badge {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms;
    margin: 0 5px;
}

.nav-notification-badge-dot {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: crimson;
}

.nav-notification-badge:hover {
    cursor: pointer;
}

.nav-notification-badge-icon {
    font-size: 1.2rem;
}

.nav-notification-badge-icon.pulse {
    animation: pulse infinite linear 1s;
}

@keyframes pulse {
    25% {
        transform: rotateZ(25deg);
    }
    75% {
        transform: rotateZ(-25deg);
    }
}

.notification-popup-container {
    position: absolute;
    z-index: 3;
    top: 60px;
    text-align: center;
    border: 1px solid var(--color-gray-border);
    border-radius: 5px;
    background-color: #FFFFFF;
    width: max-content;
}

.notification-popup-title {
    padding: 10px;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(180deg, var(--color-primary-dark), var(--color-primary-light));
    color: var(--color-light-font);
}

.notification-popup-body {
    padding: 10px;
}

.screen-backdrop {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: transparent; /* rgba(0,0,0,0.2); */
    z-index: 2;
}
