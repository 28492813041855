.page-404-container {
    text-align: center;
}

.page-404-animation-container{
    text-align: center;
    margin-top: 30px;
    height: 250px;
}

.page-404-label {
    font-size: 1.5em;
}
