.gpx-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.gpx-top {
  display: flex;
  justify-content: space-between;
  height: 60%;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.gpx-top .gpx-top-right {
  flex: 1;
  height: 100%;
  margin-left: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.gpx-top .gpx-top-left {
  display: flex;
  flex: 1;
  margin-right: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.gpx-top .video-react {
  border-radius: 5px;
}

.gpx-bottom {
  display: flex;
  justify-content: space-between;
  height: 40%;
  margin-top: 5px;
}

.gpx-bottom .gpx-bottom-right {
  flex: 1;
  margin-left: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
}
.gpx-bottom .gpx-bottom-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex: 3;
  margin-right: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
}

.gpx-distance-container {
  font-size: 3em;
  direction: ltr;
  text-align: left;
  margin-right: 10px;
  color: var(--color-primary-lighter);
}

.gpx-distance-container p {
  font-size: 0.9em;
  text-shadow: 0 0 10px var(--color-primary-lighter);
}
